export type Orchestartor = {
  instanceId: string;
  name: string;
};

export type GetAllInstancesReqDTO = {
  createdTimeFrom?: number;

  createdTimeTo?: number;

  runtimeStatus?: string;
};

export type GetSingleInstanceReqDTO = {
  // The ID of the orchestration instance.
  instanceId: string;
};

export type GetSingleInstanceResDTO = {
  name: string;

  // The runtime status of the instance. Values include Running, Pending, Failed, Canceled, Terminated, Completed.
  runtimeStatus: OrchestratorRuntimeStatus;

  // The JSON data used to initialize the instance. This field is null if the showInput query string parameter is set to false.
  input: unknown;

  // The JSON data used for custom orchestration status. This field is null if not set.
  customStatus: unknown;

  // The JSON output of the instance. This field is null if the instance is not in a completed state.
  output: unknown;

  // The time at which the instance was created. Uses ISO 8601 extended notation.
  createdTime: Date;

  // The time at which the instance last persisted. Uses ISO 8601 extended notation.
  lastUpdatedTime: Date;

  // A JSON array containing the orchestration execution history.
  // This field is null unless the showHistory query string parameter is set to true.
  historyEvents: null | OrchestratorHistoryEvent;
};

export type GetAllInstancesResDTO = GetSingleInstanceResDTO & {
  instanceId: string;
};

export type OrchestratorHistoryEvent = {
  EventType: OrchestratorHistoryEventType;
  FunctionName: string;
  Result: unknown;
  ScheduledTime: string;
  Timestamp: string;
  Reason?: string; // fail reason
  Details?: string; // fail details
  OrchestrationStatus?: OrchestratorRuntimeStatus; // exists if task is an Orchestrator
};

export enum OrchestratorHistoryEventType {
  ExecutionStarted = 'ExecutionStarted',
  TaskCompleted = 'TaskCompleted',
  ExecutionCompleted = 'ExecutionCompleted',
  TaskFailed = 'TaskFailed',
}

export enum OrchestratorRuntimeStatus {
  Pending = 'Pending', // The instance has been scheduled but has not yet started running.
  Running = 'Running', // The instance has started running.
  Completed = 'Completed', // The instance has completed normally.
  ContinuedAsNew = 'ContinuedAsNew', // The instance has restarted itself with a new history. This state is a transient state.
  Failed = 'Failed', // The instance failed with an error.
  Terminated = 'Terminated', //  The instance was stopped abruptly.
  Canceled = 'Canceled', //  The instance was stopped abruptly.
}
