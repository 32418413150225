
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { getSingleOrchestrator } from '@/api/orchestrator-monitor';

@Component({
  components: {
    Promised,
  },
})
export default class OrchestratorDetailPanel extends Vue {
  @Prop({ type: String, required: true }) instanceId!: string;
  dataLoader: Promise<unknown> | null = null;

  @Watch('instanceId')
  onInstanceIdChanged(): void {
    this.load();
  }

  load(): void {
    this.dataLoader = getSingleOrchestrator(this.instanceId);
  }

  getDateTimeStrForInput(unixTime: number): string {
    const isoString = new Date(unixTime).toISOString();
    return isoString.substring(0, ((isoString.indexOf('T') | 0) + 6) | 0);
  }

  created(): void {
    this.load();
  }
}
