
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import GButton from '@/components/gsk-components/GskButton.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import GCheckbox from '@/components/gsk-components/GskCheckbox.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import {
  getAllOrchestrators,
  terminateOrchestrators,
  purgeOrchestratorHistory,
  restartOrchestrators,
} from '@/api/orchestrator-monitor';
import {
  GetAllInstancesReqDTO,
  GetAllInstancesResDTO,
  OrchestratorRuntimeStatus,
} from '@/types/orchestrator-monitor-types';
import OrchestratorDetailPanel from './OrchestratorDetailPanel.vue';
import { Dialog } from '@gsk-tech/gsk-dialog/gsk-dialog';

const TZ_OFFSET = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

@Component({
  components: {
    GButton,
    MaxWidth,
    Promised,
    OrchestratorDetailPanel,
    GDialog,
    GCheckbox,
  },
})
export default class OrchestratorMonitor extends Vue {
  dataLoader: Promise<unknown> | null = null;
  resultData: GetAllInstancesResDTO[] = [];
  selectedTimeRangeShortcut = 7;
  runtimeStatusFilterData: Record<string, boolean> = {
    Pending: true,
    Running: true,
    Completed: true,
    Failed: true,
    Terminated: true,
    Canceled: true,
    ContinuedAsNew: true,
  };
  timeRangeFilterData: Record<string, number> = {
    createdTimeFrom: Date.now() - TZ_OFFSET - this.selectedTimeRangeShortcut * 24 * 60 * 60 * 1000,
    createdTimeTo: Date.now() - TZ_OFFSET + 0 * 24 * 60 * 60 * 1000,
  };
  filterByOrchNameText: string | null = null;
  filterByOrchId: string | null = null;
  dialogOpen = false;
  instanceIdToShowDetail = '';
  selectedInstanceIdHash: Record<string, boolean> = {};
  selectAllChecked = false;
  actionResult: Record<string, string | boolean>[] = [];
  actionResultTableTitle = '';

  @Watch('selectAllChecked')
  onSelectAllChanged(): void {
    this.resultData.forEach(currentResult => {
      Vue.set(this.selectedInstanceIdHash, currentResult.instanceId, this.selectAllChecked);
    });
  }

  isRestartEnabled(runtimeStatus: string): boolean {
    if (runtimeStatus === OrchestratorRuntimeStatus.Running) {
      return false;
    }

    if (this.selectedInstanceIds.length === 0) {
      return true;
    }

    return !this.resultData.some(item => {
      return (
        this.selectedInstanceIdHash[item.instanceId] &&
        item.runtimeStatus === OrchestratorRuntimeStatus.Running
      );
    });
  }

  isTerminateEnabled(runtimeStatus: string): boolean {
    if (runtimeStatus !== OrchestratorRuntimeStatus.Running) {
      return false;
    }

    if (this.selectedInstanceIds.length === 0) {
      return true;
    }

    return !this.resultData.some(item => {
      return (
        this.selectedInstanceIdHash[item.instanceId] &&
        item.runtimeStatus !== OrchestratorRuntimeStatus.Running
      );
    });
  }

  async onDetailsClicked(instanceId: string): Promise<void> {
    this.instanceIdToShowDetail = instanceId;
    this.orchDetailDialogEl().open();
  }

  async onTerminateClicked(instanceId: string): Promise<void> {
    const payload = this.getActionPayload(instanceId);
    let results = await terminateOrchestrators(payload);

    this.actionResult = results.data;
    this.actionResultTableTitle = 'Termination Results';
    this.actionResultDialogEl().open();
    this.load();
  }

  async onPurgeHistoryClicked(instanceId: string): Promise<void> {
    const payload = this.getActionPayload(instanceId);
    let results = await purgeOrchestratorHistory(payload);

    this.actionResult = results.data;
    this.actionResultTableTitle = 'Purge History Results';
    this.actionResultDialogEl().open();
    this.load();
  }

  async onRestartClicked(instanceId: string): Promise<void> {
    const payload = this.getActionPayload(instanceId);
    const results = await restartOrchestrators(payload);

    this.actionResult = results.data;
    this.actionResultTableTitle = 'Restart Results';
    this.actionResultDialogEl().open();
    this.load();
  }

  getActionPayload(instanceId: string): string[] {
    let payload: string[] = [];

    if (this.selectedInstanceIds.length > 0) {
      Vue.set(this.selectedInstanceIdHash, instanceId, true);
      payload = this.selectedInstanceIds;
    } else {
      payload = [instanceId];
    }
    return payload;
  }

  @Watch('selectedTimeRangeShortcut')
  onSelectedTimeRangeShortcutChanged(value: number): void {
    this.timeRangeFilterData.createdTimeFrom = Date.now() - value * 24 * 60 * 60 * 1000;
    this.timeRangeFilterData.createdTimeTo = Date.now();
    this.load();
  }

  onCreatedTimeFromChanged(e: { target: { value: string } }): void {
    this.timeRangeFilterData.createdTimeFrom = new Date(e.target.value).getTime();
  }

  onCreatedTimeToChanged(e: { target: { value: string } }): void {
    this.timeRangeFilterData.createdTimeTo = new Date(e.target.value).getTime();
  }

  load(): void {
    const reqPayload: GetAllInstancesReqDTO = {
      createdTimeTo: this.timeRangeFilterData.createdTimeTo,
      createdTimeFrom: this.timeRangeFilterData.createdTimeFrom,
      runtimeStatus: this.getRuntimeStatusFilterString(),
    };

    this.dataLoader = getAllOrchestrators(reqPayload).then(r => {
      this.selectedInstanceIdHash = {};
      this.selectAllChecked = false;
      this.resultData = r.data.filter(current => {
        return (
          this.checkMatch(this.filterByOrchNameText, current.name) &&
          this.checkMatch(this.filterByOrchId, current.instanceId)
        );
      });
      return this.resultData;
    });
  }

  checkMatch(filterValue: string | null, value: string): boolean {
    if (filterValue === null || filterValue.trim() === '') {
      return true;
    }

    return value.toLowerCase().trim().includes(filterValue.toLowerCase().trim());
  }

  getRuntimeStatusFilterString(): string {
    let resultArr: string[] = [];
    for (let currentStatus in this.runtimeStatusFilterData) {
      if (this.runtimeStatusFilterData[currentStatus]) {
        resultArr.push(currentStatus);
      }
    }

    return resultArr.join(',');
  }

  getDateTimeStrForInput(unixTime: number): string {
    const isoString = new Date(unixTime).toISOString();
    return isoString.substring(0, ((isoString.indexOf('T') | 0) + 6) | 0);
  }

  get selectedInstanceIds(): string[] {
    const resultArr = [];
    for (let currentInstanceId in this.selectedInstanceIdHash) {
      if (this.selectedInstanceIdHash[currentInstanceId]) {
        resultArr.push(currentInstanceId);
      }
    }
    return resultArr;
  }

  orchDetailDialogEl(): Dialog {
    return this.$refs.orchDetailDialog as Dialog;
  }

  actionResultDialogEl(): Dialog {
    return this.$refs.actionResultDialog as Dialog;
  }

  created(): void {
    this.load();
  }
}
