import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  GetAllInstancesReqDTO,
  GetAllInstancesResDTO,
  GetSingleInstanceResDTO,
} from '@/types/orchestrator-monitor-types';

export function getAllOrchestrators(
  payload: GetAllInstancesReqDTO,
): AxiosPromise<GetAllInstancesResDTO[]> {
  return axios.get(URLs.OrchestratorMonitorGetAll, { params: payload });
}

export function getSingleOrchestrator(instanceId: string): AxiosPromise<GetSingleInstanceResDTO> {
  return axios.get(URLs.OrchestratorMonitorGetSingle.replace(':instanceId', instanceId));
}

export function terminateOrchestrators(instanceIds: string[], reason = ''): AxiosPromise<any> {
  return axios.post(URLs.OrchestratorMonitorTerminate, { instanceIds, reason });
}

export function purgeOrchestratorHistory(instanceIds: string[]): AxiosPromise<any> {
  return axios.post(URLs.OrchestratorMonitorPurgeHistory, { instanceIds });
}

export function restartOrchestrators(instanceIds: string[]): AxiosPromise<any> {
  return axios.post(URLs.OrchestratorMonitorRestart, { instanceIds });
}
